import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion, useAnimate } from 'framer-motion'
import { Message } from './message'
import { MessageProps } from './types'

interface MessageListProps {
  messages: Omit<MessageProps, 'index'>[]
  onFinish: () => void
}

export const MessageList = ({ messages, onFinish }: MessageListProps) => {
  const [scope, animate] = useAnimate()
  const [list, setList] = useState<MessageListProps['messages']>([])
  const messageCount = useRef<number>(0)

  useEffect(() => {
    const sequence = async () => {
      // Show message list
      await animate(scope.current, { opacity: 1 })

      for (const message of messages) {
        if (messageCount.current > 0) {
          // If not first message, wait 2.5s
          await new Promise((r) => setTimeout(r, 2500))
        }
        await setList((prevState) => [...prevState, message])
        messageCount.current++
        if (messageCount.current > 3) {
          // await animate(scope.current, { y: -20, duration: 0.1, type: 'tween' });
        }
      }

      // After messages wait 2.5s and fade out
      await new Promise((r) => setTimeout(r, 2500))
      await animate(scope.current, { opacity: 0, y: 0 })
      await setList([])
      messageCount.current = 0
      onFinish()
    }

    sequence()
  }, [messages, onFinish])

  return (
    <div
      className='sm:px-5 flex-grow-1 bg-muted/50 flex flex-col justify-start h-full px-3 pt-4 overflow-y-auto opacity-0'
      ref={scope}
    >
      <AnimatePresence>
        {list.map((message, index) => (
          <Message key={message.id} {...message} index={index} />
        ))}
      </AnimatePresence>
    </div>
  )
}