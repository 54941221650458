'use client';
import { createContext, useContext } from "react";
import { ConversationProps } from "./types";

export interface ConversationContextType {
  conversations: ConversationProps[]
  currentConversation: ConversationProps;
  playing: boolean;
  handleNext: () => void;
  handlePrev: () => void;
  handlePlayPause: () => void;
  handleFinish: () => void;
}

export const ConversationsContext = createContext<ConversationContextType>({} as ConversationContextType);

export function useConversations() {
  return useContext(ConversationsContext);
}