import classNames from 'classnames'
import { motion } from 'framer-motion'
import { MessageProps } from './types'
import Linkify from 'linkify-react'
import { MessageMedia } from '@/api/types'

export const Message = ({ body, direction, media = [], index, className = '' }: MessageProps) => {
  const isOutgoing = direction === 'outgoing'
  const variants = {
    visible: (custom: number) => ({
      opacity: 1,
      y: 0,
      // transition: { delay: custom * 2.4, duration: 0.1 }
      transition: { duration: 0.1 }
    }),
    invisible: () => ({
      y: 10,
      opacity: 0
    })
  }
  return (
    <motion.div
      initial='invisible'
      custom={index}
      animate='visible'
      variants={variants}
      key={index}
    >
      <div className={classNames(isOutgoing ? 'text-right' : 'text-left', 'w-full mb-3')}>
        <div
          className={classNames(
            isOutgoing
              ? 'text-white border-transparent bg-purple-800 rounded-br-sm'
              : 'bg-white border-border text-foreground rounded-bl-sm',
            'border py-2 px-3 rounded-2xl text-xs sm:text-sm inline-block max-w-xs text-left whitespace-pre-line',
            className
          )}
        >
          {media.length ? (
            <div className={classNames(body && 'pb-1 mb-4 border-b border-border')}>
              {media.map((media: MessageMedia) => {
                if (media.contentType.includes('image')) {
                  return (
                    <img
                      key={media.url}
                      alt='Image message'
                      className='block w-full mb-2 rounded-lg'
                      src={media.url}
                    />
                  )
                } else if (media.contentType.includes('audio')) {
                  return (
                    <audio
                      key={media.url}
                      className='block w-full mb-2 rounded-lg'
                      preload='metadata'
                      controls
                    >
                      <source src={media.url} type={media.contentType} />
                    </audio>
                  )
                } else {
                  return (
                    <a
                      key={media.url}
                      href={media.url}
                      target='_blank'
                      rel='noreferrer'
                      className='break-all underline'
                    >
                      Attachment.{media.contentType.split('/').pop()}
                    </a>
                  )
                }
              })}
            </div>
          ) : null}
          <Linkify options={{ className: 'underline pointer-events-none' }}>{body}</Linkify>
        </div>
      </div>
    </motion.div>
  )
}
