'use client';
import { motion, AnimatePresence } from 'framer-motion'
import { useConversations } from './conversations-context'

export function HeroTagline({ text }: { text: string }) {
  const { currentConversation } = useConversations()
  if (currentConversation.title !== text) return null
  return (
    <motion.div
      initial={{ y: -25, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.25 } }}
      exit={{ y: 25, opacity: 0 }}
      className='bg-clip-text bg-gradient-to-r from-purple-500 text-transparent to-purple-600 via-purple-800 inline'
    >
      {text || 'for the future'}
    </motion.div>
  )
}

export function HeroHeadline() {
  const { conversations } = useConversations()
  return (
    <h1 className='text-4xl sm:text-5xl md:text-[3.3rem] font-extrabold text-foreground tracking-tight md:leading-[1.1] text-balance'>
      Text message marketing software built{' '}
      <AnimatePresence>
        {conversations.map((conversation) => (
          <HeroTagline text={conversation.title} key={conversation.title} />
        ))}
      </AnimatePresence>
      .
    </h1>
  )
}