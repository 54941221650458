'use client'
import { useState } from "react";
import { CONVERSATIONS } from "./constants";
import { ConversationsContext } from "./conversations-context";

export const ConversationsProvider = ({ children }: { children: JSX.Element }) => {
  const [currentConversationIdx, setCurrentConversationIdx] = useState(0);
  const [playing, setPlaying] = useState(true);

  const currentConversation = CONVERSATIONS[currentConversationIdx]
  const handleNext = async () => await setCurrentConversationIdx((prev) => (prev + 1) % CONVERSATIONS.length);
  const handlePrev = async () => await setCurrentConversationIdx((prev) => (prev - 1 + CONVERSATIONS.length) % CONVERSATIONS.length);
  const handlePlayPause = () => setPlaying((prev) => !prev);
  const handleFinish = async () => {
    if (playing) await handleNext();
  };

  return (
    <ConversationsContext.Provider
      value={{
        conversations: CONVERSATIONS,
        currentConversation,
        playing,
        handleNext,
        handlePrev,
        handlePlayPause,
        handleFinish,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};