'use client'
import Image from 'next/image'
import { useConversations } from './conversations-context'
import { MessageList } from './message-list'

export function DemoPhone() {
  const { currentConversation, handleFinish } = useConversations()
  return (
    <div className='-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_80%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-4 xl:-bottom-32'>
      <div className='relative aspect-[366/729] mx-auto max-w-[366px]'>
        <div className='absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl'></div>
        <div className='absolute left-[calc(23/366*100%)] top-[calc(23/729*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden bg-white'>
          <div className='flex flex-col'>
            <div className='flex justify-between items-center p-4 relative z-10 bg-white pt-[calc((23/318*100%)+1rem)]'>
              <svg viewBox='0 0 24 24' fill='none' aria-hidden='true' className='h-6 w-6 flex-none'>
                <path
                  d='M5 6h14M5 18h14M5 12h14'
                  stroke='#555'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
              </svg>
              <Image
                src='/images/icon.png'
                className='h-6 w-auto'
                width={165}
                height={175}
                alt='Helios SMS Marketing'
              />
              <svg viewBox='0 0 24 24' fill='none' aria-hidden='true' className='h-6 w-6 flex-none'>
                <path
                  d='M15 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.696 19h10.608c1.175 0 2.08-.935 1.532-1.897C18.028 15.69 16.187 14 12 14s-6.028 1.689-6.836 3.103C4.616 18.065 5.521 19 6.696 19Z'
                  stroke='#555'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
              </svg>
            </div>
            <MessageList messages={currentConversation.messages} onFinish={handleFinish} />
            {/* <div className='mt-6 flex-auto bg-white'>
              <div className='flex gap-2'>
                <div className='text-xs leading-6 text-gray-500'>Tailwind Labs, Inc.</div>
                <div className='text-sm text-gray-900'>$CSS</div>
                <svg viewBox='0 0 24 24' className='ml-auto h-6 w-6' fill='none'>
                  <path
                    d='M5 12a7 7 0 1 1 14 0 7 7 0 0 1-14 0ZM12 9v6M15 12H9'
                    stroke='#171717'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  ></path>
                </svg>
              </div>
              <List messages={MESSAGES} />
            </div> */}
          </div>
        </div>
        <svg
          viewBox='0 0 366 729'
          aria-hidden='true'
          className='pointer-events-none absolute inset-0 h-full w-full fill-gray-100'
        >
          <path
            fill='#F2F2F2'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z'
          ></path>
          <rect x='154' y='29' width='56' height='5' rx='2.5' fill='#D4D4D4'></rect>
        </svg>
        <img
          alt='Helios'
          width='366'
          height='729'
          decoding='async'
          data-nimg='1'
          className='pointer-events-none absolute inset-0 h-full w-full'
          src='https://pocket.tailwindui.com/_next/static/media/phone-frame.d4b6b62a.svg'
        />
      </div>
    </div>
  )
}